<template>
	<router-link v-if="type !== 'external'" :to="url" :target="newTab ? '_blank' : ''" :class="['link',{'link--color-primary' : color === 'primary'},
																																		{'link--color-secondary' : color === 'secondary'},
																																		{'link--color-tertiary' : color === 'tertiary'},
																																		{'link--color-dark' : color === 'dark'},
																																		{'link--color-light' : color === 'light'},
																																		{'link--size-small' : size === 'small'},
																																		{'link--size-large' : size === 'large'},
																																		{'link--type-nav' : type === 'nav'},
																																		{'link--custom' : !text && !icon},
																																		{'link--display-inline' : display === 'inline'}]">
		<div class="link__custom" v-if="!text && !icon">
			<slot></slot>
		</div>
		<Stack space="2">
			<Icon v-if="icon" :color="color" :icon="icon"></Icon>
			<div class="link__text" v-if="text">
				{{text}}
			</div>
		</Stack>
	</router-link>
	<a v-else-if="type === 'external'" :href="url" target="_blank" :class="['link', {'link--color-primary' : color === 'primary'},
																																									{'link--color-secondary' : color === 'secondary'},
																																									{'link--color-tertiary' : color === 'tertiary'},
																																									{'link--color-dark' : color === 'dark'},
																																									{'link--color-light' : color === 'light'},
																																									{'link--size-small' : size === 'small'},
																																									{'link--size-large' : size === 'large'},
																																									{'link--display-inline' : display === 'inline'}]">
		<div class="link__custom" v-if="!text && !icon">
			<slot></slot>
		</div>
		<Stack space="2">
			<Icon v-if="icon" :color="color" :icon="icon"></Icon>
			<div class="link__text" v-if="text">
				{{text}}
			</div>
		</Stack>
	</a>
</template>

<script>
export default {
	props: {
		url: String,
		text: String,
		icon: String,
		color: String,
		size: String,
		display: String,
		type: String,
		active: Boolean,
		newTab: Boolean,
	},
	data(){
		return{
			
		}
	},
	methods: {
	},
}

</script>

<style lang="scss" scoped>
.link{
	text-decoration: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	position: relative;
}
.link--custom{
	display:block;
}
.link__text{
	font-family: var(--link-font-family);
	font-size: var(--link-font-size);
	font-weight: var(--link-font-weight);
	color: var(--link-default-color);
	text-transform: var(--link-text-transform);
	letter-spacing: var(--link-text-letter-spacing);
	text-decoration: var(--link-text-decoration);
	transition: var(--link-text-transition);
}
.link:hover{
	.link__text{
		text-decoration: var(--link-hover-text-decoration);
	}
}
.link__custom{
	text-decoration: none;
	display: block;
	width: 100%;
}
.link--color-primary{
	.link__text{
		color: var(--link-color-primary);
	}
}
.link--color-secondary{
	.link__text{
		color: var(--link-color-secondary);
	}
}
.link--color-tertiary{
	.link__text{
		color: var(--link-color-tertiary);
	}
}
.link--color-light{
	.link__text{
		color: var(--link-color-light);
	}
}
.link--color-dar{
	.link__text{
		color: var(--link-color-dar);
	}
}
.link--size-small{
	.link__text{
		font-size: var(--link-small-font-size);
	}
}
.link--size-large{
	.link__text{
		font-size: var(--link-large-font-size);
	}
}
.link--display-inline{
	display: inline-block;
	width: auto;

	.link__text{
		display: inline-block;
		width: auto;
	}
}

.link--type-nav{
	.link__text{
		line-height: var(--link-text-line-height);
		padding: var(--link-text-padding);
	}
	&.is-active{
		background: var(--link-active-background);
		.link__text{
			color: var(--link-active-color);
		}
	}
}


// link inline paragraph - inherit paragraph styles
.paragraph{
	.link__text{
		font-family: inherit;
		font-size: inherit;
		font-weight: inherit;
		color: var(--link-inline-paragraph-color);
		text-decoration: var(--link-inline-paragraph-text-decoration);
		letter-spacing: inherit;
		text-transform: none;
		line-height: initial;
		padding:0;
	}
	.link__text:hover{
		text-decoration: var(--link-inline-paragraph-hover-text-decoration);
	}
}
.heading{
	.link__text{
		font-family: inherit;
		font-size: inherit;
		font-weight: inherit;
		color: var(--link-inline-paragraph-color);
		text-decoration: var(--link-inline-paragraph-text-decoration);
		letter-spacing: inherit;
		text-transform: none;
		line-height: initial;
		padding:0;
	}
	.link__text:hover{
		text-decoration: var(--link-inline-paragraph-hover-text-decoration);
	}
}

</style>

<!-- Used in : AR, DS, SD -->
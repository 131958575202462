var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.type !== 'external')?_c('router-link',{class:['link',{'link--color-primary' : _vm.color === 'primary'},
																																	{'link--color-secondary' : _vm.color === 'secondary'},
																																	{'link--color-tertiary' : _vm.color === 'tertiary'},
																																	{'link--color-dark' : _vm.color === 'dark'},
																																	{'link--color-light' : _vm.color === 'light'},
																																	{'link--size-small' : _vm.size === 'small'},
																																	{'link--size-large' : _vm.size === 'large'},
																																	{'link--type-nav' : _vm.type === 'nav'},
																																	{'link--custom' : !_vm.text && !_vm.icon},
																																	{'link--display-inline' : _vm.display === 'inline'}],attrs:{"to":_vm.url,"target":_vm.newTab ? '_blank' : ''}},[(!_vm.text && !_vm.icon)?_c('div',{staticClass:"link__custom"},[_vm._t("default")],2):_vm._e(),_c('Stack',{attrs:{"space":"2"}},[(_vm.icon)?_c('Icon',{attrs:{"color":_vm.color,"icon":_vm.icon}}):_vm._e(),(_vm.text)?_c('div',{staticClass:"link__text"},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e()],1)],1):(_vm.type === 'external')?_c('a',{class:['link', {'link--color-primary' : _vm.color === 'primary'},
																																								{'link--color-secondary' : _vm.color === 'secondary'},
																																								{'link--color-tertiary' : _vm.color === 'tertiary'},
																																								{'link--color-dark' : _vm.color === 'dark'},
																																								{'link--color-light' : _vm.color === 'light'},
																																								{'link--size-small' : _vm.size === 'small'},
																																								{'link--size-large' : _vm.size === 'large'},
																																								{'link--display-inline' : _vm.display === 'inline'}],attrs:{"href":_vm.url,"target":"_blank"}},[(!_vm.text && !_vm.icon)?_c('div',{staticClass:"link__custom"},[_vm._t("default")],2):_vm._e(),_c('Stack',{attrs:{"space":"2"}},[(_vm.icon)?_c('Icon',{attrs:{"color":_vm.color,"icon":_vm.icon}}):_vm._e(),(_vm.text)?_c('div',{staticClass:"link__text"},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }